import constants from './constants';

export const fetchAll = async() => {
    let apiUrl = new URL(constants.URL_ENDPOINT);

    return await fetch(apiUrl.href).then(response => {
        if (response.ok) {
            return response;
        }

        throw new Error(response.statusText);
    });

};
