import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import * as orderClient from '../context/orders-client';
import constants from '../context/constants';
import { pageReducer } from '../context/reducer';

const hours = new Date().getHours();

const initState = {
    title: 'Retail Express by Maropost Order Visualisation',
    isDayTime: hours > 6 && hours < 20,
    ordersData: [],
    pageStatus: constants.PAGE_STATUS_LOADING
};

const PageContext = createContext();

const PageProvider = ({ initialState = initState, children }) => {
    const [ state, dispatch ] = useReducer(pageReducer, initialState);

    const fetchOrders = async() => {
        await orderClient.fetchAll()
            .then(response => {
                if (response.status === 204) {
                    return [];
                }

                return response.json();
            })
            .then(response => {
                if (response.length) {
                    dispatch({ type: constants.UPDATE_ORDERS, data: response });
                    dispatch({ type: constants.UPDATE_PAGE_STATUS, data: constants.PAGE_STATUS_LOADED });
                }
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error('Error fetching orders:', error);

                dispatch({ type: constants.UPDATE_PAGE_STATUS, data: constants.PAGE_STATUS_ERROR });
            });
            //.finally(()=>{

            //    if (state.pageStatus !== constants.PAGE_STATUS_ERROR) {
            //        fetchOrders();
            //    }
            //});
    };

    useEffect(() => {
        fetchOrders();
    }, []);

     useEffect(() => {
         // Get new data
         const interval = setInterval(() => fetchOrders(), 10 * 1000);

         if (state.pageStatus === constants.PAGE_STATUS_ERROR) {
             clearInterval(interval);
         }

         return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [ state.pageStatus ]);

    return (
        <PageContext.Provider value={{ state, dispatch }}>
            { children }
        </PageContext.Provider>
    );
};

export { PageContext, PageProvider, initState };

PageProvider.propTypes = {
    initialState: PropTypes.object,
    children: PropTypes.node
};

PageProvider.defaultProps = {
    initialState: initState
};
