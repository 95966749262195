const environment = process.env.REACT_APP_ENV || 'uat';

const urls = {
    uat: 'https://localhost:44313/api/order',
    staging: 'https://uatappasvsdb001.azurewebsites.net/api/order',
    //production: 'https://uatappasvsdb001.azurewebsites.net/api/order',
    production: 'https://retailworldorders.maropost.com/api/order'
};

const urlEndpoint = urls[environment];

export default {
    CHANGE_DAY_TIME: 'CHANGE_DAY_TIME',
    UPDATE_ORDERS: 'UPDATE_ORDERS',
    UPDATE_PAGE_STATUS: 'UPDATE_PAGE_STATUS',
    URL_ENDPOINT: urlEndpoint,
    PAGE_STATUS_LOADING: 'loading',
    PAGE_STATUS_LOADED: 'loaded',
    PAGE_STATUS_ERROR: 'error'
};

const OPACITY = 0.7;

export const salesChannelColourMap = {
    'In Store': `rgba(255, 255, 255, ${OPACITY}))`,
    Online: `rgba(231, 21, 69, ${OPACITY}))`,
    Other: `rgba(255, 255, 255, ${OPACITY}))`
};
 